<template>
  <div class="detail">
    <nf-breadcrumb :breadList="breadList"></nf-breadcrumb>
    <div class="msgWrap">
      <div class="imgWrap">
        <img :src="detail.surfacePlot" alt="" />
      </div>
      <div class="msg">
        <div class="title">{{ detail.title }}</div>
        <div class="time">
          <span>{{ detail.tags }}</span>
          {{ detail.createdDate }}
        </div>
        <div class="price">¥ {{ detail.budget }}</div>
        <div class="type">成果类型：{{ detail.type === 1 ? '技术提供' : '技术转移' }}</div>
        <div class="phone">联系方式：{{ detail.phone }}</div>
        <div class="address">地区：{{ detail.province + detail.city }}</div>
      </div>
    </div>
    <div class="descWrap">
      <div class="title">成果描述</div>
      <div class="content">{{ detail.details }}</div>
    </div>
    <div class="responderWrap">
      <div class="title">响应者</div>
      <el-collapse>
        <el-collapse-item :title="`全部响应 (累计${total}条响应)`">
          <div class="leaveItem" v-for="item in leaveList" :key="item.id">
            <el-row type="flex">
              <el-col :span="3">
                <div class="imgWrap"><img :src="item.userImg" alt="" /></div>
                <div class="name">{{ item.createdUser }}</div>
              </el-col>
              <el-col :span="21">
                <div class="content">{{ item.content }}</div>
                <div class="time">{{ item.createdDate }}</div>
              </el-col>
            </el-row>
          </div>
          <div class="leaveItem" v-if="!leaveList || leaveList.length <= 0">暂无留言</div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div class="similarListWrap">
      <div class="title">相关推荐</div>
      <div class="similarList">
        <div class="item" v-for="item in achievementRespList" :key="item.id" @click="toDetail(item.id)">
          <div class="imgWrap">
            <img :src="item.surfacePlot" alt="" />
          </div>
          <div class="content">
            <div class="money">¥ {{ item.budget }}</div>
            <div class="name">{{ item.title }}</div>
            <div class="address"><i style="color: #92acef" class="el-icon-location-outline"></i> {{ item.province + item.city }}</div>
          </div>
        </div>
        <div v-if="!achievementRespList || achievementRespList.length <= 0" class="noData">
          <img src="@/assets/images/noData.png" alt="" />
          <p>暂无相关数据</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import nfBreadcrumb from '@/component/breadcrumb/breadcrumb';
import nfPagination from '@/component/pagination/index';
import { Message } from 'element-ui';

export default {
  name: 'achievementDetail',
  data() {
    return {
      breadList: [{ name: '热点功能' }, { name: '成果转化', path: '/achievementList' }, { name: '详情' }], // 导航
      detail: {}, // 详情
      detailId: this.$route.query.id, // 成果id
      from: this.$route.query.from,
      leaveList: [], // 响应列表
      total: 0 // 响应总数
    };
  },
  methods: {
    // 获取详情
    async getDetail() {
      let api = '';
      if (this.from === '1') {
        api = this.$fetchApi.achievementDetail.api + this.detailId;
      } else {
        api = this.$fetchApi.demandDetail.api + this.detailId;
      }
      const data = await this.$fetchData.fetchPost({}, api, 'json');
      if (data.code === '200') {
        this.detail = data.result;
      }
    },
    // 获取响应列表
    async getList() {
      const params = {
        pageNum: 1,
        pageSize: 999,
        achievementId: this.detailId
      };
      if (this.from === '1') {
        params.identitySign = 0;
      } else {
        params.identitySign = 1;
      }
      const api = this.$fetchApi.leaveList.api;
      const data = await this.$fetchData.fetchPost(params, api, 'json');
      if (data.code === '200') {
        this.total = data.result.length;
        this.leaveList = data.result;
      } else {
        Message.warning(data.message);
      }
    },
    // 跳转详情
    toDetail(id) {
      this.$router.push({
        path: `/achievementDetail?from=${this.from}&id=${id}`
      });
    }
  },
  created() {
    this.getDetail();
    this.getList();
  },
  computed: {
    achievementRespList() {
      const list = this.detail.achievementRespList;
      if (list && list.length >= 5) {
        return list.slice(0, 5);
      } else {
        return list;
      }
    }
  },
  components: { nfBreadcrumb }
};
</script>

<style lang="less" scoped>
.descWrap,
.msgWrap,
.responderWrap,
.similarListWrap {
  width: 1200px;
  margin: 20px auto;
}
.msgWrap {
  overflow: hidden;
  .imgWrap {
    width: 264px;
    height: 280px;
    float: left;
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  .msg {
    overflow: hidden;
    padding-left: 20px;
    .title {
      font-size: 28px;
    }
    .time {
      color: #5c5c5c;
      padding: 15px 0 30px 0;
      span {
        padding: 3px 6px;
        background-color: #92acef;
        border-radius: 15px;
        color: #fff;
        font-size: 14px;
        text-align: center;
        margin-right: 20px;
      }
    }
    .price {
      height: 90px;
      background: #fafafa;
      line-height: 90px;
      font-size: 20px;
      color: #ff5454;
      text-indent: 1em;
      margin-bottom: 5px;
    }
    .type,
    .phone,
    .address {
      padding-top: 5px;
    }
  }
}
.descWrap,
.responderWrap,
.similarListWrap {
  padding-top: 20px;
  .title {
    font-size: 16px;
    font-weight: 900;
    border-left: 6px solid #265ae0;
    padding: 2px 20px;
    margin-bottom: 20px;
  }
}
.descWrap {
  .content {
    text-indent: 2em;
    line-height: 27px;
    letter-spacing: 1px;
  }
}
.responderWrap {
  .el-collapse {
    border: 0;
  }
  /deep/ .el-collapse-item__header {
    padding-left: 20px;
    font-size: 14px;
    background: #fafafa !important;
    border: 0;
  }
  /deep/ .el-collapse-item__wrap {
    border: 0;
  }
  .leaveItem {
    padding: 20px;
    color: #5c5c5c;
    border-bottom: 1px solid #eaeaea;
    .imgWrap {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      float: left;
      margin-right: 20px;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .name {
      line-height: 30px;
      overflow: hidden;
    }
    .content {
      padding-bottom: 5px;
    }
    .content,
    .time {
      font-size: 16px;
    }
  }
}
.similarList {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 20px;
  .item {
    width: 224px;
    height: 310px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    margin-bottom: 20px;
    margin-right: 20px;
    .imgWrap {
      height: 200px;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .content {
      padding: 10px;
      height: 110px;
      box-sizing: border-box;
      line-height: 30px;
      .money {
        font-size: 24px;
        color: #ff5454;
      }
      .name {
        font-size: 14px;
      }
      .address {
      }
    }
  }
  .item:nth-child(5n) {
    margin-right: 0;
  }
}
.noData {
  margin: 0 auto;
  img {
    // width: 100%;
    height: 200px;
  }
  p {
    text-align: center;
    font-size: 30px;
    color: #999;
    padding: 30px;
  }
}
</style>
